import { Card, Col } from "antd";
import DeliveryOrderTable from "./DeliveryOrderTable";
import { useState } from "react";
import { Mode, Sync } from "../models/delivery-order.model";

const SyncView = () => {
  const [etaList, setEtaList] = useState<Sync[]>([]);
  const [loading, setLoading] = useState(false);

  const getDataEta = () => {};

  return (
    <Col span={24}>
      <Card>
        <DeliveryOrderTable
          mode={Mode.SYNC}
          dataSource={[]}
          isLoading={loading}
          onChange={getDataEta}
          syncDataSource={etaList}
        />
      </Card>
    </Col>
  );
};

export default SyncView;
