import { Alert, Col, Row, Space, Table, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { Eta, Mode, Sync } from "../models/delivery-order.model";
import dayjs from "dayjs";
import PopoverAction from "./PopoverAction";
import DeliveryOrderService from "../services/delivery-order-service";
import ConfirmModal from "../../../shared/component/ConfirmModal";
import { CheckCircleOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ButtonDs } from "design-system";
import { useNavigate } from "react-router-dom";

interface Props {
  mode: Mode;
  dataSource: Eta[];
  syncDataSource: Sync[];
  isLoading: boolean;
  onChange: () => void;
}

const DeliveryOrderTable = ({ dataSource, isLoading, onChange, mode, syncDataSource }: Props) => {
  const [isConfirmSendMessageModalOpen, setIsConfirmSendMessageModalOpen] = useState(false);
  const [selectdDcCode, setSelectdDcCode] = useState<string>("");
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [messageError, setMessageError] = useState<string | null>(null);
  const [isModalConfirmDeleteOpen, setIsModalConfirmDeleteOpen] = useState(false);
  const navigate = useNavigate();

  const columns: ColumnsType<Eta> = [
    {
      title: "DC",
      dataIndex: "dc_code",
      key: "dc_code",
      width: 50,
      align: "center",
      fixed: "left",
    },
    {
      title: "File name",
      dataIndex: "filename",
      key: "filename",
      width: 150,
    },
    {
      title: "Upload by",
      dataIndex: "uploaded_by",
      key: "uploaded_by",
      width: 100,
    },
    {
      title: "Upload time",
      dataIndex: "uploaded_time",
      key: "uploaded_time",
      width: 80,
      render: (_: string, record: Eta) => (
        <>
          <div>{record.uploaded_time && dayjs(record.uploaded_time).format("DD-MM-YYYY, HH:mm")}</div>
        </>
      ),
    },
    {
      title: "Record Uploaded",
      key: "record_uploaded",
      width: 60,
      align: "center",
      render: (_: string, record: Eta) => {
        return <>{record.record_uploaded > 0 ? record.record_uploaded : ""} </>;
      },
    },
    {
      title: "Send by",
      dataIndex: "sent_notification_by",
      key: "send_by",
      width: 100,
      render: (_: string, record: Eta) => (
        <>
          {record.record_uploaded > 0 && (
            <>
              {record.sent_notification_by && record.sent_notification_by !== ""
                ? record.sent_notification_by
                : "-"}
            </>
          )}
        </>
      ),
    },
    {
      title: "Send time",
      dataIndex: "sent_notification_time",
      key: "send_time",
      width: 80,
      render: (_: string, record: Eta) => (
        <>
          {record.record_uploaded > 0 && (
            <div>
              {record.sent_notification_time != null
                ? record.sent_notification_time &&
                  dayjs(record.sent_notification_time).format("DD-MM-YYYY, HH:mm")
                : "-"}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Action",
      width: 60,
      key: "action",
      align: "center",
      render: (_, record) => {
        return record.record_uploaded > 0 && <PopoverAction record={record} onAction={handleAction} />;
      },
    },
  ];

  const syncColumns: ColumnsType<Sync> = [
    {
      title: "DC",
      dataIndex: "dc_code",
      key: "dc_code",
      width: 50,
      align: "center",
      fixed: "left",
    },
    {
      title: "Last sync timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      width: 100,
    },
    {
      title: "Number of records",
      dataIndex: "record_sent",
      key: "record_sent",
      width: 100,
    },
    {
      title: "Send by",
      dataIndex: "sent_by",
      key: "send_by",
      width: 100,
    },
    {
      title: "Send time",
      dataIndex: "sent_time",
      key: "send_time",
      width: 100,
    },
  ];

  const handleAction = (result: { type: string; record: Eta }) => {
    setSelectdDcCode(result.record.dc_code);
    if (result.type === "download_latest_data") {
      DeliveryOrderService.downloadLatestFile(result.record.dc_code).then(
        (response) => {
          const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];
          createFile(filename, response.data);
        },
        (error) => {
          console.log("error", error);
        },
      );
    }
    if (result.type === "download_original_data") {
      DeliveryOrderService.downloadOriginalFile(result.record.dc_code).then(
        (response) => {
          const filename = response.headers["content-disposition"].split(";")[1].split("=")[1];
          createFile(filename, response.data);
        },
        (error) => {
          console.log("error", error);
        },
      );
    }
    if (result.type === "send_message") {
      setIsConfirmSendMessageModalOpen(true);
    }
    if (result.type === "delete") {
      setIsModalConfirmDeleteOpen(true);
    }
  };

  const sendMessage = () => {
    setIsConfirmLoading(true);
    DeliveryOrderService.sendLineMessage(selectdDcCode).then(
      () => {
        setIsConfirmLoading(false);
        setIsConfirmSendMessageModalOpen(false);
        openNotiSuccess("ส่งข้อความสำเร็จ");
        onChange();
        setTimeout(() => {
          navigate("/messaging-status");
        }, 500);
      },
      () => {
        setIsConfirmLoading(false);
        setMessageError("เกิดข้อผิดพลาดในการส่งข้อความ");
        onChange();
      },
    );
  };

  const onClickDeleteOK = () => {
    setIsConfirmLoading(true);
    DeliveryOrderService.deleteEta(selectdDcCode).then(
      () => {
        setIsConfirmLoading(false);
        setIsModalConfirmDeleteOpen(false);
        openNotiSuccess("ลบข้อมูลสำเร็จ");
        onChange();
      },
      () => {
        setIsConfirmLoading(false);
        setMessageError("เกิดข้อผิดพลาดในการลบข้อมูล");
        onChange();
      },
    );
  };

  const createFile = (fileName: string, data: Blob) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(data);
    link.setAttribute("download", decodeURI(fileName));
    link.click();
  };

  const openNotiSuccess = (message: string) => {
    notificationComponent.success({
      message: <h4 style={{ fontSize: "16px" }}>{message}</h4>,
      description: "",
      className: "success-notification",
      icon: <CheckCircleOutlined style={{ color: "#41A447" }} />,
      placement: "topRight",
    });
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ display: "flex", alignItems: "baseline" }}>
          <h3>รายการข้อมูลการจัดส่ง</h3>
          <div className="data-date-text">
            ข้อมูลจัดส่งของวันที่ : {dayjs(new Date()).format("DD-MM-YYYY")}
          </div>
        </Col>
        <Col span={24}>
          {mode === Mode.SYNC ? (
            <Table
              className="table-custom-design-system"
              columns={syncColumns}
              dataSource={syncDataSource}
              loading={isLoading}
              pagination={false}
              rowKey={"dc_code"}
            />
          ) : (
            <Table
              className="table-custom-design-system"
              columns={columns}
              dataSource={dataSource}
              loading={isLoading}
              pagination={false}
              rowKey={"dc_code"}
            />
          )}
        </Col>
      </Row>
      <ConfirmModal
        open={isConfirmSendMessageModalOpen}
        closeIcon={true}
        title={
          <Space>
            <QuestionCircleOutlined /> ยืนยันการส่งข้อความหรือไม่ ?
          </Space>
        }
        onClickConfirmCancel={() => {
          setMessageError(null);
          setIsConfirmSendMessageModalOpen(false);
        }}
        onClickConfirmOK={sendMessage}
        loading={isConfirmLoading}
      >
        <p>
          ต้องการส่งข้อความแจ้งเตือนการจัดส่งหรือไม่
          <br />
          กรุณายืนยัน
        </p>
        {messageError && (
          <div style={{ margin: "12px" }}>
            <Alert
              message={<h3>{messageError}</h3>}
              description={
                <div>
                  ไม่สามารถส่งข้อความได้
                  <br />
                  กรุณาลองใหม่อีกครั้ง
                </div>
              }
              type="error"
              showIcon
            />
          </div>
        )}
      </ConfirmModal>
      <ConfirmModal
        open={isModalConfirmDeleteOpen}
        closeIcon={true}
        title={
          <Space>
            <DeleteOutlined /> ยืนยันการลบข้อมูลหรือไม่?
          </Space>
        }
        footer={
          <ButtonDs
            htmlType="submit"
            type="primary"
            onClick={onClickDeleteOK}
            loading={isConfirmLoading}
            danger
          >
            <DeleteOutlined />
            ลบข้อมูล
          </ButtonDs>
        }
        onClickConfirmCancel={() => {
          setMessageError(null);
          setIsModalConfirmDeleteOpen(false);
        }}
        onClickConfirmOK={sendMessage}
        loading={isConfirmLoading}
      >
        <p>
          หากข้อมูลถูกลบจะไม่สามารถกู้คืนได้
          <br />
          กรุณายืนยันการลบข้อมูล
        </p>
        {messageError && (
          <div style={{ margin: "12px" }}>
            <Alert
              message={<h3>{messageError}</h3>}
              description={
                <div>
                  ไม่สามารถลบข้อมูลได้ในขณะนี้
                  <br />
                  กรุณาลองใหม่อีกครั้ง
                </div>
              }
              type="error"
              showIcon
            />
          </div>
        )}
      </ConfirmModal>
      {contextHolderNoti}
    </>
  );
};

export default DeliveryOrderTable;
